
import ActionTypes from '../actions/actionTypes' ;

const INITIAL_STATE = {
    usersData : [],
    error: {},
}

export default ( state={INITIAL_STATE} , action={} ) => {
    switch(action.type) {
        case (ActionTypes.GetUserList):
            return ({
                ...state,
                usersData : action.payload,
            });
        default :
            return state ;
    }
}